import {
	AppBar,
	Button,
	Divider,
	LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Paper,
	SwipeableDrawer,
	Toolbar,
	Typography,
	makeStyles,
} from '@material-ui/core';
import {
	AccountCircleOutlined,
	DashboardOutlined,
	GroupOutlined,
	MenuOutlined,
	NotesOutlined,
	ViewArrayOutlined,
} from '@material-ui/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout as Logout } from '../../actions/AccountsActions';
import { Routes } from '../../routes';
// import DateFnsUtils from '@date-io/date-fns'
// import { MuiPickersUtilsProvider,	KeyboardDatePicker,	} from '@material-ui/pickers'
// const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
const iOS = false;
const mql = window.matchMedia(`(max-width: 1024px)`);

export const useStyles = {
	drawer: { backgroundColor: '#303E52', border: 'none' },
	listItems: { width: '240px' },
	appBarTitle: { flexGrow: 1 },
	largeInput: { width: '40%' },
	searchField: { width: '97.8%', margin: '1.6%', marginLeft: '0' },
	unpaidInvoiceRow: { borderLeft: '4px solid rgb(255,122,0)' },
	paidInvoiceRow: { borderLeft: '4px solid blue' },
	dialogPaper: {
		minHeight: '80vh',
		maxHeight: '80vh',
	},
};

export const AdminDrawer = ({
	state,
	activeLink,
	toggleDrawer,
	openProfile,
	closeProfile,
	profileAnchorEl,
	logout,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { admin } = useSelector(state => ({
		admin: state.admin,
	}));

	const classes = makeStyles(useStyles)();

	return (
		<div>
			<SwipeableDrawer
				PaperProps={{ classes: { root: classes.drawer } }}
				open={state.drawerOpen}
				variant={mql.matches ? 'temporary' : 'permanent'}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
				disableBackdropTransition={!iOS}
				disableDiscovery={iOS}
			>
				{
					<div
						className={classes.listItems}
						role='presentation'
						onClick={toggleDrawer(false)}
						onKeyDown={toggleDrawer(false)}
					>
						<Paper className='dashboard_drawer_logo'></Paper>
						<Paper className='dashboard_drawer_links'>
							<List>
								<Link to={Routes.AdminDash} className='links'>
									<ListItem
										style={{
											background: activeLink === 'dash' ? 'whitesmoke' : '#303E52',
											color: activeLink === 'dash' ? '#232C39' : 'white',
										}}
									>
										<ListItemIcon>
											<DashboardOutlined
												htmlColor={activeLink === 'dash' ? '#303E52' : 'white'}
											/>
										</ListItemIcon>
										<ListItemText>Dashboard</ListItemText>
									</ListItem>
								</Link>
								<Link to={Routes.AdminOfficers} className='links'>
									<ListItem
										style={{
											background: activeLink === 'ofc' ? 'whitesmoke' : '#303E52',
											color: activeLink === 'ofc' ? '#232C39' : 'white',
										}}
									>
										<ListItemIcon>
											<GroupOutlined htmlColor={activeLink === 'ofc' ? '#303E52' : 'white'} />
										</ListItemIcon>
										<ListItemText>Officers</ListItemText>
									</ListItem>
								</Link>
								<Link to={Routes.AdminClients} className='links'>
									<ListItem
										style={{
											background: activeLink === 'clnts' ? 'whitesmoke' : '#303E52',
											color: activeLink === 'clnts' ? '#232C39' : 'white',
										}}
									>
										<ListItemIcon>
											<GroupOutlined htmlColor={activeLink === 'clnts' ? '#303E52' : 'white'} />
										</ListItemIcon>
										<ListItemText>Clients</ListItemText>
									</ListItem>
								</Link>
								<Link to={Routes.AdminInvoices} className='links'>
									<ListItem
										style={{
											background: activeLink === 'inv' ? 'whitesmoke' : '#303E52',
											color: activeLink === 'inv' ? '#232C39' : 'white',
										}}
									>
										<ListItemIcon>
											<NotesOutlined htmlColor={activeLink === 'inv' ? '#303E52' : 'white'} />
										</ListItemIcon>
										<ListItemText>Invoices</ListItemText>
									</ListItem>
								</Link>
								<Link to={Routes.AdminReceipts} className='links'>
									<ListItem
										style={{
											background: activeLink === 'rec' ? 'whitesmoke' : '#303E52',
											color: activeLink === 'rec' ? '#232C39' : 'white',
										}}
									>
										<ListItemIcon>
											<NotesOutlined htmlColor={activeLink === 'rec' ? '#303E52' : 'white'} />
										</ListItemIcon>
										<ListItemText>Receipts</ListItemText>
									</ListItem>
								</Link>
								<Link to={Routes.AdminVariables} className='links'>
									<ListItem
										style={{
											background: activeLink === 'var' ? 'whitesmoke' : '#303E52',
											color: activeLink === 'var' ? '#232C39' : 'white',
										}}
									>
										<ListItemIcon>
											<ViewArrayOutlined htmlColor={activeLink === 'var' ? '#303E52' : 'white'} />
										</ListItemIcon>
										<ListItemText>Variables</ListItemText>
									</ListItem>
								</Link>
								{/*<Link to={Routes.AdminMerchants} className="links">
                  <ListItem style={{
                    background: activeLink === 'merch'? 'whitesmoke' : '#303E52',
                    color: activeLink === 'merch'? '#232C39' : 'white'}}>
                    <ListItemIcon><StoreOutlined htmlColor={activeLink === 'merch'? "#303E52" : "white"} /></ListItemIcon>
                    <ListItemText>Merchants</ListItemText>
                  </ListItem>
                  </Link>*/}
							</List>
						</Paper>
						<Divider />
					</div>
				}
			</SwipeableDrawer>
			<AppBar
				position='fixed'
				style={{
					paddingLeft: mql.matches ? '0' : '240px',
					background: 'whitesmoke',
					color: '#232C39',
				}}
			>
				<Toolbar>
					<Button onClick={toggleDrawer(true)}>
						<MenuOutlined htmlColor='#303E52' style={{ display: mql.matches ? 'block' : 'none' }} />
					</Button>
					<Typography variant='h6' className={classes.appBarTitle}></Typography>
					{/* Datepicker */}
					{/* {activeLink === 'dash'?
            (<form><MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker variant="dialog" format="MM/dd/yyyy"
              margin="normal" value={state.startDate} label="From date:"
              onChange={date => changeStartDate(date)} />
            </MuiPickersUtilsProvider>
            &nbsp;&nbsp;
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker variant="dialog" format="MM/dd/yyyy"
              margin="normal" value={state.endDate} label="To date:"
              onChange={date => changeEndDate(date)} />
            </MuiPickersUtilsProvider>&nbsp;&nbsp;
            Range:&nbsp;<span style={{color: 'orange'}}>
              {state.endDate.diff(state.startDate, 'days')}&nbsp;Days
            </span>&nbsp;&nbsp;</form>)
          : <span></span>} */}
					<Button onClick={openProfile}>
						<AccountCircleOutlined htmlColor='#303E52' />
					</Button>
					{/* Profile Options */}
					<Menu
						id='simple-menu'
						anchorEl={profileAnchorEl}
						keepMounted
						open={Boolean(profileAnchorEl)}
						onClose={closeProfile}
					>
						<MenuItem
							onClick={() => {
								logout();
								dispatch(Logout(navigate, 1));
							}}
						>
							Logout
						</MenuItem>
					</Menu>
				</Toolbar>
				<div className='linear_progress'>
					<LinearProgress style={{ display: admin && admin.fetchingData ? 'block' : 'none' }} />
				</div>
			</AppBar>
		</div>
	);
};
