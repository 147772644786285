import {
	Bars3BottomLeftIcon,
	CheckBadgeIcon,
	CreditCardIcon,
	LockClosedIcon,
	QuestionMarkCircleIcon,
	Squares2X2Icon,
	UserGroupIcon,
	ViewColumnsIcon,
} from '@heroicons/react/24/solid';
import { AccountCircleOutlined, MenuOutlined, NotificationsNoneOutlined } from '@material-ui/icons';
import {
	AppBar,
	Badge,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Paper,
	SwipeableDrawer,
	Toolbar,
	Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createSelector } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout as Logout } from '../../actions/AccountsActions';
import { dashGetUserInfoAction } from '../../actions/DashAction';
import { Routes } from '../../routes';
import { useAppSelector } from '../../store/store';
// import DateFnsUtils from '@date-io/date-fns'
// import { MuiPickersUtilsProvider,	KeyboardDatePicker,	} from '@material-ui/pickers'
// const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
const iOS = false;
const mql = window.matchMedia(`(max-width: 1024px)`);

export const useStyles = {
	drawer: { backgroundColor: '#303E52' },
	listItems: { width: '240px' },
	appBarTitle: { flexGrow: 1 },
	largeInput: { width: '40%' },
	searchField: { width: '97.8%', margin: '1.6%', marginLeft: '0' },
	unpaidInvoiceRow: { borderLeft: '4px solid rgb(255,122,0)' },
	paidInvoiceRow: { borderLeft: '4px solid blue' },
};

export const Sidebar = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const selectProps = createSelector(
		[
			state => state.auth.isAdmin,
			state => state.auth.isOfficer,
			state => state.auth.isClient,
			state => state.dash.userInfo,
			state => state.dash.recentUploads,
			state => state.dash.recentUploadsSuccess,
			state => state.dash.userInfoFetched,
			state => state.dash.userAddress,
		],
		(isAdmin, isOfficer, isClient, userInfo, recentUploads, recentUploadsSuccess, userInfoFetched, userAddress) => {
			return {
				isAdmin,
				isOfficer,
				isClient,
				userInfo,
				recentUploads,
				recentUploadsSuccess,
				userInfoFetched,
				userAddress,
			};
		}
	);

	const {
		isAdmin,
		isOfficer,
		isClient,
		userInfo,
		recentUploads,
		recentUploadsSuccess,
		userInfoFetched,
		userAddress,
	} = useAppSelector(selectProps);

	const [profileAnchorEl, setProfileAnchorEl] = useState(null);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [notificationsDialogOpen, setNotificationsDialogOpen] = useState(false);
	const [dashTourOpen, setDashTourOpen] = useState(!localStorage.getItem('dashtour'));
	const [notifications, setNotifications] = useState([]);
	const [state, setState] = useState({
		receivedItems: [],
		eligibleItems: [],
		shippedItems: [],
	});

	useEffect(() => {
		if (isClient) {
			dispatch(dashGetUserInfoAction());
		}
	}, [isClient]);

	useEffect(
		() => {
			if (isClient && location.pathname !== Routes.EditProfile && userInfoFetched) {
				if (userInfo.firstName === null || userInfo.lastName === null || userInfo.phoneNumber === null) {
					navigate(Routes.EditProfile);
				}
				if (!userAddress || userAddress.length === 0) {
					navigate(Routes.EditProfile);
				}
			}
		},
		// eslint-disable-next-line
		[userInfo, userInfoFetched, userAddress]
	);

	useEffect(
		() => {
			if (recentUploadsSuccess) {
				let receivedItems = [];
				let shippedItems = [];
				let eligibleItems = [];
				recentUploads &&
					recentUploads.map(item => {
						if (item.packageStatus === 'IN_DELAWARE') receivedItems.push(item);
						if (item.packageStatus === 'IN_TRANSIT') shippedItems.push(item);
						if (item.isEligibleForProtection === true) eligibleItems.push(item);
						return item;
					});
				setState({ ...state, receivedItems, shippedItems, eligibleItems });
			}
		},
		// eslint-disable-next-line
		[recentUploadsSuccess, recentUploads]
	);

	const { receivedItems, eligibleItems, shippedItems } = state;

	const receivedItemsLength = receivedItems.length;
	const eligibleItemsLength = eligibleItems.length;
	const shippedItemsLength = shippedItems.length;
	let notificationsLength = notifications && notifications.length;

	if (receivedItemsLength !== 0) {
		notificationsLength = notificationsLength + 1;
	}
	if (eligibleItemsLength !== 0) {
		notificationsLength = notificationsLength + 1;
	}
	if (shippedItemsLength !== 0) {
		notificationsLength = notificationsLength + 1;
	}

	const classes = makeStyles(useStyles)();

	const openProfile = event => {
		setProfileAnchorEl(event.currentTarget);
	};

	const closeProfile = event => {
		setProfileAnchorEl(null);
	};

	const toggleDrawer = open => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setDrawerOpen(open);
	};

	const toggleNotifications = () => {
		setNotificationsDialogOpen(!notificationsDialogOpen);
	};

	const onHelp = () => {
		// setDashTourOpen(true);
		localStorage.setItem('dashtour', new Date());
	};

	const SideLinks = [
		// Admin
		{
			name: 'Dashboard',
			icon: (
				<Squares2X2Icon
					className={`h-6 w-6 ${location.pathname === Routes.AdminDash ? 'text-orange-600' : 'text-neutral-600'}`}
				/>
			),
			to: Routes.AdminDash,
			role: isAdmin,
			active: location.pathname === Routes.AdminDash ? 'text-orange-600' : 'text-neutral-600',
		},
		{
			name: 'Officers',
			icon: (
				<UserGroupIcon
					className={`h-6 w-6 ${location.pathname === Routes.AdminOfficers ? 'text-orange-600' : 'text-neutral-600'}`}
				/>
			),
			to: Routes.AdminOfficers,
			role: isAdmin,
			active: location.pathname === Routes.AdminOfficers ? 'text-orange-600' : 'text-neutral-600',
		},

		{
			name: 'Clients',
			icon: (
				<UserGroupIcon
					className={`h-6 w-6 ${location.pathname === Routes.AdminClients ? 'text-orange-600' : 'text-neutral-600'}`}
				/>
			),
			to: Routes.AdminClients,
			role: isAdmin,
			active: location.pathname === Routes.AdminClients ? 'text-orange-600' : 'text-neutral-600',
		},
		{
			name: 'Invoices',
			icon: (
				<Bars3BottomLeftIcon
					className={`h-6 w-6 ${location.pathname === Routes.AdminInvoices ? 'text-orange-600' : 'text-neutral-600'}`}
				/>
			),
			to: Routes.AdminInvoices,
			role: isAdmin,
			active: location.pathname === Routes.AdminInvoices ? 'text-orange-600' : 'text-neutral-600',
		},
		{
			name: 'Receipts',
			icon: (
				<Bars3BottomLeftIcon
					className={`h-6 w-6 ${location.pathname === Routes.AdminReceipts ? 'text-orange-600' : 'text-neutral-600'}`}
				/>
			),
			to: Routes.AdminReceipts,
			role: isAdmin,
			active: location.pathname === Routes.AdminReceipts ? 'text-orange-600' : 'text-neutral-600',
		},
		{
			name: 'Variables',
			icon: (
				<ViewColumnsIcon
					className={`h-6 w-6 ${location.pathname === Routes.AdminVariables ? 'text-orange-600' : 'text-neutral-600'}`}
				/>
			),
			to: Routes.AdminVariables,
			role: isAdmin,
			active: location.pathname === Routes.AdminVariables ? 'text-orange-600' : 'text-neutral-600',
		},
		// Officer
		{
			name: 'Dashboard',
			icon: (
				<Squares2X2Icon
					className={`h-6 w-6 ${location.pathname === Routes.OfficerLocalOfficer ? 'text-orange-600' : 'text-neutral-600'}`}
				/>
			),
			to: Routes.OfficerLocalOfficer,
			role: isOfficer,
			active: location.pathname === Routes.OfficerLocalOfficer ? 'text-orange-600' : 'text-neutral-600',
		},
		{
			name: 'Clients',
			icon: (
				<UserGroupIcon
					className={`h-6 w-6 ${location.pathname === Routes.OfficerClients ? 'text-orange-600' : 'text-neutral-600'}`}
				/>
			),
			to: Routes.OfficerClients,
			role: isOfficer,
			active: location.pathname === Routes.OfficerClients ? 'text-orange-600' : 'text-neutral-600',
		},
		{
			name: 'Invoices',
			icon: (
				<Bars3BottomLeftIcon
					className={`h-6 w-6 ${location.pathname === Routes.OfficerInvoices ? 'text-orange-600' : 'text-neutral-600'}`}
				/>
			),
			to: Routes.OfficerInvoices,
			role: isOfficer,
			active: location.pathname === Routes.OfficerInvoices ? 'text-orange-600' : 'text-neutral-600',
		},
		{
			name: 'Receipts',
			icon: (
				<Bars3BottomLeftIcon
					className={`h-6 w-6 ${location.pathname === Routes.OfficerReceipts ? 'text-orange-600' : 'text-neutral-600'}`}
				/>
			),
			to: Routes.OfficerReceipts,
			role: isOfficer,
			active: location.pathname === Routes.OfficerReceipts ? 'text-orange-600' : 'text-neutral-600',
		},
		{
			name: 'Stats',
			icon: (
				<Bars3BottomLeftIcon
					className={`h-6 w-6 ${location.pathname === Routes.OfficerStats ? 'text-orange-600' : 'text-neutral-600'}`}
				/>
			),
			to: Routes.OfficerStats,
			role: isOfficer,
			active: location.pathname === Routes.OfficerStats ? 'text-orange-600' : 'text-neutral-600',
		},
		{
			name: 'Change Password',
			icon: (
				<LockClosedIcon
					className={`h-6 w-6 ${location.pathname === Routes.OfficerNewPassword ? 'text-orange-600' : 'text-neutral-600'}`}
				/>
			),
			to: Routes.OfficerNewPassword,
			role: isOfficer,
			active: location.pathname === Routes.OfficerNewPassword ? 'text-orange-600' : 'text-neutral-600',
		},
		// Clients
		{
			name: 'Dashboard',
			icon: (
				<Squares2X2Icon
					className={`h-6 w-6 ${location.pathname === Routes.Dashboard ? 'text-orange-600' : 'text-neutral-600'}`}
				/>
			),
			to: Routes.Dashboard,
			role: isClient,
			active: location.pathname === Routes.Dashboard ? 'text-orange-600' : 'text-neutral-600',
		},
		{
			name: 'Track Items',
			icon: (
				<CheckBadgeIcon
					className={`h-6 w-6 ${location.pathname === Routes.RegisteredItems ? 'text-orange-600' : 'text-neutral-600'}`}
				/>
			),
			to: Routes.RegisteredItems,
			role: isClient,
			active: location.pathname === Routes.RegisteredItems ? 'text-orange-600' : 'text-neutral-600',
		},
		{
			name: 'Invoicing',
			icon: (
				<Bars3BottomLeftIcon
					className={`h-6 w-6 ${location.pathname === Routes.Invoices ? 'text-orange-600' : 'text-neutral-600'}`}
				/>
			),
			to: Routes.Invoices,
			role: isClient,
			active: location.pathname === Routes.Invoices ? 'text-orange-600' : 'text-neutral-600',
		},
		{
			name: 'Receipts',
			icon: (
				<Bars3BottomLeftIcon
					className={`h-6 w-6 ${location.pathname === Routes.Receipts ? 'text-orange-600' : 'text-neutral-600'}`}
				/>
			),
			to: Routes.Receipts,
			role: isClient,
			active: location.pathname === Routes.Receipts ? 'text-orange-600' : 'text-neutral-600',
		},
		{
			name: 'Pricing',
			icon: (
				<CreditCardIcon
					className={`h-6 w-6 ${location.pathname === Routes.Pricing ? 'text-orange-600' : 'text-neutral-600'}`}
				/>
			),
			to: Routes.Pricing,
			role: isClient,
			active: location.pathname === Routes.Pricing ? 'text-orange-600' : 'text-neutral-600',
		},
		{
			name: 'Help',
			icon: (
				<QuestionMarkCircleIcon
					className={`h-6 w-6 ${location.pathname === '' ? 'text-orange-600' : 'text-neutral-600'}`}
				/>
			),
			to: '',
			onClick: onHelp,
			role: isClient,
			active: location.pathname === '' ? 'text-orange-600' : 'text-neutral-600',
		},
		{
			name: 'Frequently Asked Questions',
			to: Routes.FAQs,
			role: isClient,
			active: location.pathname === Routes.FAQs ? 'text-orange-600' : 'text-neutral-600',
		},
		{
			name: 'Terms and Conditions',
			to: Routes.TermsAndConditions,
			role: isClient,
			active: location.pathname === Routes.TermsAndConditions ? 'text-orange-600' : 'text-neutral-600',
		},
	];

	const Notifications =
		notifications &&
		notifications.length > 0 &&
		notifications.map((notification, index) => {
			const colors = ['crimson', 'orange', 'cyan', '#00FFAA', 'lime'];
			const color = colors[Math.floor(Math.random() * colors.length)];
			return (
				<Paper
					key={index}
					className='dash_notification_paper'
					style={{ borderLeft: `4px solid ${color || '#00FFAA'}` }}
				>
					{notification}
					<Button style={{ color: 'blue' }}>Read</Button>
				</Paper>
			);
		});

	return (
		<div>
			<SwipeableDrawer
				PaperProps={{ classes: { root: classes.drawer } }}
				open={drawerOpen}
				variant={mql.matches ? 'temporary' : 'permanent'}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
				disableBackdropTransition={!iOS}
				disableDiscovery={iOS}
			>
				{
					<div
						className={classes.listItems}
						role='presentation'
						onClick={toggleDrawer(false)}
						onKeyDown={toggleDrawer(false)}
					>
						<Paper className='dashboard_clear_drawer_logo'></Paper>
						<Paper className='dashboard_drawer_links'>
							<List>
								{SideLinks.filter(link => link.role).map((link, index) => {
									return (
										<Link
											onClick={() => {
												if (link.to === '') {
													link.onClick();
												}
											}}
											to={link.to && link.to}
											className='links border-0'
											key={index}
										>
											<ListItem className={`border-0 ${link.active}`}>
												{link.icon && <ListItemIcon>{link.icon}</ListItemIcon>}
												<ListItemText>{link.name}</ListItemText>
											</ListItem>
										</Link>
									);
								})}
							</List>
						</Paper>
						<Divider />
					</div>
				}
			</SwipeableDrawer>
			<AppBar
				position='fixed'
				style={{
					paddingLeft: mql.matches ? '0' : '240px',
					background: 'whitesmoke',
					color: '#232C39',
				}}
			>
				<Toolbar>
					<Button onClick={toggleDrawer(true)}>
						<MenuOutlined htmlColor='#303E52' style={{ display: mql.matches ? 'block' : 'none' }} />
					</Button>
					<Typography variant='h6' className={classes.appBarTitle}></Typography>
					{/* Datepicker */}
					{/* {activeLink === 'dash'?
            (<form><MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker variant="dialog" format="MM/dd/yyyy"
              margin="normal" value={state.startDate} label="From date:"
              onChange={date => changeStartDate(date)} />
            </MuiPickersUtilsProvider>
            &nbsp;&nbsp;
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker variant="dialog" format="MM/dd/yyyy"
              margin="normal" value={state.endDate} label="To date:"
              onChange={date => changeEndDate(date)} />
            </MuiPickersUtilsProvider>&nbsp;&nbsp;
            Range:&nbsp;<span style={{color: 'orange'}}>
              {state.endDate.diff(state.startDate, 'days')}&nbsp;Days
            </span>&nbsp;&nbsp;</form>)
          : <span></span>} */}
					{isClient && (
						<>
							<Button onClick={toggleNotifications}>
								<Badge badgeContent={notifications.length} showZero color='primary'>
									<NotificationsNoneOutlined className='text-orange-500' />
								</Badge>
							</Button>
						</>
					)}
					<Button onClick={openProfile} className=''>
						<AccountCircleOutlined className='text-orange-500' />
						&nbsp;
						{mql.matches || ('firstName' in userInfo && userInfo.firstName === null) ? (
							''
						) : (
							<span className='text-orange-500'>
								{userInfo.firstName} {userInfo.lastName}
							</span>
						)}
					</Button>
					{/* Profile Options */}
					<Menu
						id='simple-menu'
						anchorEl={profileAnchorEl}
						keepMounted
						open={Boolean(profileAnchorEl)}
						onClose={closeProfile}
					>
						<MenuItem onClick={closeProfile} className='text-neutral-600'>
							{userInfo.firstName}&nbsp;{userInfo.lastName}
						</MenuItem>
						<MenuItem
							onClick={() => {
								dispatch(Logout(navigate, 1));
							}}
							className='text-neutral-600'
						>
							Logout
						</MenuItem>
					</Menu>
				</Toolbar>
				<div className='linear_progress'>
					{/* <LinearProgress style={{ display: admin && admin.fetchingData ? 'block' : 'none' }} /> */}
				</div>
			</AppBar>
			{/* Notifications */}
			<Dialog open={notificationsDialogOpen} onClose={toggleNotifications} scroll='paper'>
				<DialogTitle>Notifications</DialogTitle>
				<DialogContent>
					{Notifications}
					<Paper
						className='dash_notification_paper'
						style={{
							borderLeft: '4px solid crimson',
							display: receivedItemsLength === 0 ? 'none' : 'block',
						}}
					>
						<span style={{ color: 'blue' }}>{receivedItemsLength}&nbsp;</span>
						Item(s) have been received at the warehouse.
						<Button onClick={() => navigate(`${Routes.RegisteredItems}?tab=1`)} style={{ color: 'blue' }}>
							View items
						</Button>
					</Paper>
					<Paper
						className='dash_notification_paper'
						style={{
							borderLeft: '4px solid orange',
							display: eligibleItemsLength === 0 ? 'none' : 'block',
						}}
					>
						<span style={{ color: 'blue' }}>{eligibleItemsLength}&nbsp;</span>
						Item(s) are eligible for protection
						<Button onClick={() => navigate(Routes.EligibleItems)} style={{ color: 'blue' }}>
							View items
						</Button>
					</Paper>
					<Paper
						className='dash_notification_paper'
						style={{
							borderLeft: '4px solid cyan',
							display: shippedItemsLength === 0 ? 'none' : 'block',
						}}
					>
						<span style={{ color: 'blue' }}>{shippedItemsLength}&nbsp;</span>
						Item(s) have been shipped
						<Button onClick={() => navigate(`${Routes.RegisteredItems}?tab=2`)} style={{ color: 'blue' }}>
							View items
						</Button>
					</Paper>
				</DialogContent>
			</Dialog>
		</div>
	);
};
