import {
	AppBar,
	Button,
	Divider,
	LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Paper,
	SwipeableDrawer,
	Toolbar,
	Typography,
	makeStyles,
} from '@material-ui/core';
import {
	AccountCircleOutlined,
	DashboardOutlined,
	GroupOutlined,
	LockOutlined,
	MenuOutlined,
	NotesOutlined,
} from '@material-ui/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout as Logout } from '../../../actions/AccountsActions';
import { Routes } from '../../../routes';

// const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
const iOS = false;
const mql = window.matchMedia(`(max-width: 1024px)`);

export const useStyless = makeStyles({
	drawer: { backgroundColor: '#303E52', border: 'none' },
	listItems: { width: '240px' },
	appBarTitle: { flexGrow: 1 },
	smallbutton: { padding: 0, minHeight: 0, minWidth: 0 },
	smallinput: { width: '46%', margin: '2%', minHeight: 0, minWidth: 0 },
	largerinput: { width: '36vw', minHeight: 0, minWidth: 0 },
	searchField: { width: '97.8%', margin: '1.6%', marginLeft: '0' },
	unpaidInvoiceRow: { borderLeft: '4px solid rgb(255,122,0)' },
	paidInvoiceRow: { borderLeft: '4px solid blue' },
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
});

export const useStyles = {
	drawer: { backgroundColor: '#303E52', border: 'none' },
	listItems: { width: '240px' },
	appBarTitle: { flexGrow: 1 },
	smallbutton: { padding: 0, minHeight: 0, minWidth: 0 },
	smallinput: { width: '46%', margin: '2%', minHeight: 0, minWidth: 0 },
	largerinput: { width: '36vw', minHeight: 0, minWidth: 0 },
	searchField: { width: '97.8%', margin: '1.6%', marginLeft: '0' },
	unpaidInvoiceRow: { borderLeft: '4px solid rgb(255,122,0)' },
	paidInvoiceRow: { borderLeft: '4px solid blue' },
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
};

export const LocalOfficerDrawer = ({
	state,
	activeLink,
	toggleDrawer,
	openProfile,
	closeProfile,
	profileAnchorEl,
	logout,
	hooks,
	fetchingStatusDates,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const classes = makeStyles(useStyles)();

	const { officer } = useSelector(state => ({
		officer: state.officer,
	}));

	return (
		<div>
			<SwipeableDrawer
				PaperProps={{ classes: { root: classes.drawer } }}
				open={state.drawerOpen}
				variant={mql.matches ? 'temporary' : 'permanent'}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
				disableBackdropTransition={!iOS}
				disableDiscovery={iOS}
			>
				{
					<div
						className={classes.listItems}
						role='presentation'
						onClick={toggleDrawer(false)}
						onKeyDown={toggleDrawer(false)}
					>
						<Paper className='dashboard_drawer_logo'></Paper>
						<Paper className='dashboard_drawer_links'>
							<List>
								<Link to={Routes.OfficerLocalOfficer} className='links'>
									<ListItem
										style={{
											background: activeLink === 'dash' ? 'whitesmoke' : '#303E52',
											color: activeLink === 'dash' ? '#232C39' : 'white',
										}}
									>
										<ListItemIcon>
											<DashboardOutlined
												htmlColor={activeLink === 'dash' ? '#303E52' : 'white'}
											/>
										</ListItemIcon>
										<ListItemText>Dashboard</ListItemText>
									</ListItem>
								</Link>
								{/* <Link to={Routes.OfficerMessages} className="links">
                  <ListItem style={{
                    background: activeLink === 'iss'? 'whitesmoke' : '#303E52',
                    color: activeLink === 'iss'? '#232C39' : 'white'}}>
                    <ListItemIcon><MessageOutlined htmlColor={activeLink === 'iss'? "#303E52" : "white"} /></ListItemIcon>
                    <ListItemText>
                      Messages
                      <Badge badgeContent={officer.messages.length} showZero color="primary">
                        <MessageOutlined htmlColor="#303E52" />
                      </Badge>
                    </ListItemText>                  
                  </ListItem>
                </Link> */}
								<Link to={Routes.OfficerClients} className='links'>
									<ListItem
										style={{
											background: activeLink === 'clnts' ? 'whitesmoke' : '#303E52',
											color: activeLink === 'clnts' ? '#232C39' : 'white',
										}}
									>
										<ListItemIcon>
											<GroupOutlined htmlColor={activeLink === 'clnts' ? '#303E52' : 'white'} />
										</ListItemIcon>
										<ListItemText>Clients</ListItemText>
									</ListItem>
								</Link>
								<Link to={Routes.OfficerInvoices} className='links'>
									<ListItem
										style={{
											background: activeLink === 'inv' ? 'whitesmoke' : '#303E52',
											color: activeLink === 'inv' ? '#232C39' : 'white',
										}}
									>
										<ListItemIcon>
											<NotesOutlined htmlColor={activeLink === 'inv' ? '#303E52' : 'white'} />
										</ListItemIcon>
										<ListItemText>Invoices</ListItemText>
									</ListItem>
								</Link>
								<Link to={Routes.OfficerReceipts} className='links'>
									<ListItem
										style={{
											background: activeLink === 'rec' ? 'whitesmoke' : '#303E52',
											color: activeLink === 'rec' ? '#232C39' : 'white',
										}}
									>
										<ListItemIcon>
											<NotesOutlined htmlColor={activeLink === 'rec' ? '#303E52' : 'white'} />
										</ListItemIcon>
										<ListItemText>Receipts</ListItemText>
									</ListItem>
								</Link>
								{/* <Link to={Routes.OfficerArchived} className="links">
                  <ListItem style={{
                    background: activeLink === 'arc' ? 'whitesmoke' : '#303E52',
                    color: activeLink === 'arc' ? '#232C39' : 'white'
                  }}>
                    <ListItemIcon><Archive htmlColor={activeLink === 'arc' ? "#303E52" : "white"} /></ListItemIcon>
                    <ListItemText>Archived</ListItemText>
                  </ListItem>
                </Link> */}
								<Link to={Routes.OfficerNewPassword} className='links'>
									<ListItem
										style={{
											background: activeLink === 'npwd' ? 'whitesmoke' : '#303E52',
											color: activeLink === 'npwd' ? '#232C39' : 'white',
										}}
									>
										<ListItemIcon>
											<LockOutlined htmlColor={activeLink === 'npwd' ? '#303E52' : 'white'} />
										</ListItemIcon>
										<ListItemText>Change Password</ListItemText>
									</ListItem>
								</Link>
							</List>
						</Paper>
						<Divider />
					</div>
				}
			</SwipeableDrawer>
			<AppBar
				position='fixed'
				style={{
					paddingLeft: mql.matches ? '0' : '240px',
					background: 'whitesmoke',
					color: '#232C39',
				}}
			>
				<Toolbar>
					<Button onClick={toggleDrawer(true)}>
						<MenuOutlined htmlColor='#303E52' style={{ display: mql.matches ? 'block' : 'none' }} />
					</Button>
					<Typography variant='h6' className={classes.appBarTitle}></Typography>
					<Button onClick={openProfile}>
						<AccountCircleOutlined htmlColor='#303E52' />
						&nbsp;
						{mql.matches
							? ''
							: `  ${(state.officerInfo && state.officerInfo.first_name) || ''} ${(state.officerInfo && state.officerInfo.last_name) || ''}`}
					</Button>
					{/* Profile Options */}
					<Menu
						id='simple-menu'
						anchorEl={profileAnchorEl}
						keepMounted
						open={Boolean(profileAnchorEl)}
						onClose={closeProfile}
					>
						<MenuItem onClick={closeProfile}>
							{(state.officerInfo && state.officerInfo.first_name) || ''}&nbsp;
							{(state.officerInfo && state.officerInfo.last_name) || ''}
						</MenuItem>
						<MenuItem
							onClick={() => {
								if (!hooks) {
									logout();
								}
								dispatch(Logout(navigate, 1));
							}}
						>
							Logout
						</MenuItem>
					</Menu>
				</Toolbar>
				<div className='linear_progress'>
					<LinearProgress
						style={
							hooks
								? { display: fetchingStatusDates ? 'block' : 'none' }
								: { display: officer && officer.fetchingData ? 'block' : 'none' }
						}
					/>
				</div>
			</AppBar>
		</div>
	);
};
