import {
	AppBar,
	Button,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Paper,
	SwipeableDrawer,
	Toolbar,
	Typography,
	makeStyles,
} from '@material-ui/core';
import { AccountCircleOutlined, GroupOutlined, LockOutlined, MenuOutlined } from '@material-ui/icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout as Logout } from '../../actions/AccountsActions';
import { Routes } from '../../routes';

// const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
const iOS = false;
const mql = window.matchMedia(`(max-width: 1024px)`);

export const useStyles = {
	drawer: { backgroundColor: '#303E52', border: 'none' },
	listItems: { width: '240px' },
	appBarTitle: { flexGrow: 1 },
};

export const useStyless = makeStyles({
	drawer: { backgroundColor: '#303E52', border: 'none' },
	listItems: { width: '240px' },
	appBarTitle: { flexGrow: 1 },
});

export const OfficerDrawer = ({
	classes,
	state,
	activeLink,
	toggleDrawer,
	openProfile,
	closeProfile,
	profileAnchorEl,
	logout,
}) => {
	const dispatch = useDispatch();

	return (
		<div>
			<SwipeableDrawer
				PaperProps={{ classes: { root: classes.drawer } }}
				open={state.drawerOpen}
				variant={mql.matches ? 'temporary' : 'permanent'}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
				disableBackdropTransition={!iOS}
				disableDiscovery={iOS}
			>
				{
					<div
						className={classes.listItems}
						role='presentation'
						onClick={toggleDrawer(false)}
						onKeyDown={toggleDrawer(false)}
					>
						<Paper className='dashboard_drawer_logo'></Paper>
						<Paper className='dashboard_drawer_links'>
							<List>
								<Link to={Routes.OfficerReceipts} className='links'>
									<ListItem
										style={{
											background: activeLink === 'rec' ? 'whitesmoke' : '#303E52',
											color: activeLink === 'rec' ? '#232C39' : 'white',
										}}
									>
										<ListItemIcon>
											<GroupOutlined htmlColor={activeLink === 'rec' ? '#303E52' : 'white'} />
										</ListItemIcon>
										<ListItemText>Officers</ListItemText>
									</ListItem>
								</Link>
								<Link to={Routes.OfficerNewPassword} className='links'>
									<ListItem
										style={{
											background: activeLink === 'npwd' ? 'whitesmoke' : '#303E52',
											color: activeLink === 'npwd' ? '#232C39' : 'white',
										}}
									>
										<ListItemIcon>
											<LockOutlined htmlColor={activeLink === 'npwd' ? '#303E52' : 'white'} />
										</ListItemIcon>
										<ListItemText>Change Password</ListItemText>
									</ListItem>
								</Link>
							</List>
						</Paper>
						<Divider />
					</div>
				}
			</SwipeableDrawer>
			<AppBar
				position='fixed'
				style={{
					paddingLeft: mql.matches ? '0' : '240px',
					background: 'whitesmoke',
					color: '#232C39',
				}}
			>
				<Toolbar>
					<Button onClick={toggleDrawer(true)}>
						<MenuOutlined htmlColor='#303E52' style={{ display: mql.matches ? 'block' : 'none' }} />
					</Button>
					<Typography variant='h6' className={classes.appBarTitle}></Typography>
					<Button onClick={openProfile}>
						<AccountCircleOutlined htmlColor='#303E52' />
					</Button>
					{/* Profile Options */}
					<Menu
						id='simple-menu'
						anchorEl={profileAnchorEl}
						keepMounted
						open={Boolean(profileAnchorEl)}
						onClose={closeProfile}
					>
						<MenuItem
							onClick={() => {
								logout();
								dispatch(Logout(history, 1));
							}}
						>
							Logout
						</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>
		</div>
	);
};
