import { Avatar, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../routes';
import { useAppSelector } from '../../../store/store';
import stringConverter, { addComma } from '../../../utils/stringConverter';
import Loader from '../../common/loader/Loader';

const mql = window.matchMedia(`(max-width: 1024px)`);

const InvoicesTable = ({ invoices, invoicesFetched }) => {
	const navigate = useNavigate();

	const selectProps = createSelector([state => state.auth.isAdmin], isAdmin => {
		return {
			isAdmin,
		};
	});

	const { isAdmin } = useAppSelector(selectProps);

	const headCells = [
		{ id: 'invsclnno', numeric: true, disablePadding: true, label: 'Client No.', align: 'center' },
		{ id: 'invsinvoicno', numeric: true, disablePadding: false, label: 'Invoice No.', align: 'left' },
		{ id: 'invsdate', numeric: true, disablePadding: false, label: 'Date', align: 'left' },
		{ id: 'invssts', numeric: true, disablePadding: false, label: 'Status', align: 'left' },
		{ id: 'invsbld', numeric: true, disablePadding: false, label: 'Balance Due', align: 'left' },
		{ id: 'invsact', numeric: true, disablePadding: false, label: 'View', align: 'left' },
	];

	return (
		<div className='tables'>
			<Table
				aria-labelledby='tableTitle'
				aria-label='enhanced table'
				className='table'
				style={{ display: mql.matches ? 'none' : 'blocsk' }}
			>
				<TableHead className='table-header table-header-bd'>
					<TableRow>
						{headCells.map(headCell => (
							<TableCell
								className='cell'
								key={headCell.id}
								align={headCell.align}
								padding={headCell.disablePadding ? 'none' : 'normal'}
							>
								{headCell.label}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				{invoicesFetched ? (
					<TableBody className='table-body'>
						{invoices &&
							invoices.map((invoice, index) => {
								return (
									<TableRow
										hover
										tabIndex={-1}
										key={index}
										className={`table-row ${invoice.paymentStatus === 'FULLY_PAID' ? 'paid-row' : 'unpaid-row'}`}
									>
										<TableCell className='cell' align='center'>
											{invoice.clientNumber.toUpperCase()}
										</TableCell>
										<TableCell className='cell' align='left'>
											{invoice.id.toUpperCase()}
										</TableCell>
										<TableCell className='cell' align='left'>
											{invoice.invoiceUpdatedOn &&
												format(invoice.invoiceUpdatedOn, 'MMM dd, yyyy')}
										</TableCell>
										<TableCell className='cell' align='left'>
											{stringConverter(invoice.paymentStatus)}
										</TableCell>
										<TableCell className='cell' align='left'>
											<span>
												USD. {invoice.totalCostUsd} - KES. {addComma(invoice.totalCostKes)}
											</span>
										</TableCell>
										<TableCell className='clickable cell' align='left'>
											<button
												onClick={() => {
													if (isAdmin) {
														navigate(Routes.AdminInvoice.replace(':id', `${invoice.id}`));
													} else {
														navigate(Routes.OfficerInvoice.replace(':id', `${invoice.id}`));
													}
												}}
											>
												View
											</button>
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				) : (
					<div className='absolute top-1/2 left-1/2'>
						<Loader />
					</div>
				)}
			</Table>
			{/* Mobile screens */}
			<Table style={{ display: mql.matches ? 'block' : 'none' }} className='table-mobi'>
				{invoicesFetched ? (
					<TableBody className='table-body-mobi'>
						{invoices &&
							invoices.map((invoice, index) => {
								return (
									<TableRow
										key={index}
										className={`table-row-mobi ${invoice.paymentStatus === 'FULLY_PAID' ? 'paid-row' : 'unpaid-row'}`}
									>
										<TableCell className='cell'>
											<Typography className='pt-5'>
												<Avatar>
													<small>{index + 1}</small>
												</Avatar>
											</Typography>
											<Typography className='pt-7'>
												<b>Client No.:&nbsp;</b>
												{invoice.clientNumber.toUpperCase()}
											</Typography>
											<Typography className='pt-2'>
												<b>Invoice No.:&nbsp;</b>
												{invoice.id.toUpperCase()}
											</Typography>
											<Typography className='pt-2'>
												<b>Date:&nbsp;</b>
												{invoice.invoiceUpdatedOn &&
													format(invoice.invoiceUpdatedOn, 'MMM dd, yyyy')}
											</Typography>
											<Typography className='pt-2'>
												<b>Shipping cost:&nbsp;</b>USD. {invoice.totalCostUsd} - KES.{' '}
												{addComma(invoice.totalCostKes)}
											</Typography>
											<Typography className='pt-3 clickable'>
												<button
													onClick={() => {
														if (isAdmin) {
															navigate(
																Routes.AdminInvoice.replace(':id', `${invoice.id}`)
															);
														} else {
															navigate(
																Routes.OfficerInvoice.replace(':id', `${invoice.id}`)
															);
														}
													}}
												>
													View
												</button>
											</Typography>
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				) : (
					<div className='flex w-full justify-around'>
						<Loader />
					</div>
				)}
			</Table>
		</div>
	);
};

export default InvoicesTable;
